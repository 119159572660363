import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BbsApi } from '../../lib/repository/bbs/bbs.api';
import { IBbs } from '../../lib/repository/bbs/bbs.model';
import { BrandService } from '../../lib/services/brand.service';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class HomeLayoutBbsStateItem extends StateItem<{
  [key: string]: IBbs[];
}> {
  constructor(
    private bbsApi: BbsApi,
    private brandService: BrandService,
  ) {
    super({});
  }

  loadBbs(idIn: number[]): void {
    if (this.value[idIn.join('_')]) {
      return;
    }

    this.bbsApi
      .findPage({
        idIn: idIn,
        sort: 'sortNumber,asc',
        brandId: this.brandService.brand.id,
      })
      .subscribe((page) => {
        this.setValue({ ...this.value, [idIn.join('_')]: page.content });
      });
  }

  getList$(idIn: number[]): Observable<IBbs[]> {
    return this.value$.pipe(map((value) => value[idIn.join('_')]));
  }

  getList(idIn: number[]): IBbs[] {
    return this.value[idIn.join('_')];
  }
}
