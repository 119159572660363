import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PartnersApi } from '../../lib/repository/partners/partners.api';
import { IPartners } from '../../lib/repository/partners/partners.model';
import { BrandService } from '../../lib/services/brand.service';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class HomeLayoutPartnersStateItem extends StateItem<{
  [key: string]: IPartners[];
}> {
  constructor(
    private partnersApi: PartnersApi,
    private brandService: BrandService,
  ) {
    super({});
  }

  loadPartners(idIn: number[]): void {
    if (this.value[idIn.join('_')]) {
      return;
    }

    this.partnersApi
      .findPage({
        idIn: idIn,
        sort: 'sort,asc',
        brandId: this.brandService.brand.id,
      })
      .subscribe((page) => {
        this.setValue({ ...this.value, [idIn.join('_')]: page.content });
      });
  }

  getList$(idIn: number[]): Observable<IPartners[]> {
    return this.value$.pipe(map((value) => value[idIn.join('_')]));
  }

  getList(idIn: number[]): IPartners[] {
    return this.value[idIn.join('_')];
  }
}
