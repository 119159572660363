import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';
import { IPartners } from './partners.model';

@Injectable({
  providedIn: 'root',
})
export class PartnersApi extends PageRepositoryService<IPartners> {
  override baseUri = `api/partners`;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected override http: HttpClient) {
    super(http);
  }
}
